import { AptosClient } from 'aptos';
// @ts-nocheck


const NODE_URL = 'https://fullnode.mainnet.aptoslabs.com';
export const restClient = new AptosClient(NODE_URL, {
  WITH_CREDENTIALS: false,
});
export const CONTRACT_ADDRESS = '0xf5aa2181866403f8c8e7d811712761ee2bb17d4f231020c95d19cad5797bf4a8';

export async function getGlobalStore() {
  const result = await restClient.getAccountResource(CONTRACT_ADDRESS, `${CONTRACT_ADDRESS}::wen::GlobalStore`);
  console.log(result);
  return result;
  
}

export async function getUpgradeList() {
  const result = await restClient.getAccountResource(CONTRACT_ADDRESS, `${CONTRACT_ADDRESS}::wen::GlobalStore`);
  // @ts-ignore
  const upgrades = result.data.upgrades;
  // for each upgrade add the key value to the value object and return the array
  const upgradesArray = upgrades.data.map((x) => {
    return {
      ...x.value,
      id: x.key,
      wps: x.value.wpm / 60,
    };
  });
  
  return upgradesArray;
  
}


export async function getResources(address) {
  console.log(address);
  
  const result = await restClient.getAccountResources(address);
  console.log(result);
  

  return result;
}


export async function getWenNumber() {
  const result = await restClient.getAccountResource(CONTRACT_ADDRESS, `${CONTRACT_ADDRESS}::wen::GameStore`);

  // @ts-ignore
  return result.data.count;
}

export async function getYourWenNumber(address) {
  const result = await restClient.getAccountResource(address, `${CONTRACT_ADDRESS}::wen::GameStore`);

  const data = result.data;
  console.log(data);
  // @ts-ignore
  if (data.last_claimed_timestamp_seconds) {
    // get time between now and last claimed
    const now = new Date().getTime();
    // @ts-ignore
    const lastClaimed = new Date(data.last_claimed_timestamp_seconds * 1000).getTime();
    const secondsSinceLastClaim = (now - lastClaimed) / 1000;
    console.log(secondsSinceLastClaim);

  }

  // @ts-ignore
  return result.data.count;
}

export async function getUserInfo(address) {
  const result = await restClient.getAccountResource(address, `${CONTRACT_ADDRESS}::wen::GameStore`);

  return result.data;
}

export async function getYourEvents(address) {
  const events: any = await restClient.getEventsByEventHandle(
    address,
    `${CONTRACT_ADDRESS}::wen::Counter`,
    'events'
  );

  const returnValue = events.map((x) => {
    return {
      num: x.data.wen_number,
      address: x.data.clicker_address,
    };
  });

  return returnValue;
}

export async function getEvents() {
  const events: any = await restClient.getEventsByEventHandle(
    CONTRACT_ADDRESS,
    `${CONTRACT_ADDRESS}::wen::Counter`,
    'events'
  );

  const returnValue = events.map((x) => {
    return {
      num: x.data.wen_number,
      address: x.data.clicker_address,
    };
  });

  return returnValue;
}
