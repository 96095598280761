// import React, { useState } from 'react';
import MainPage from './pages';


const App: React.FC = () => { 
  // const queryParams = new URLSearchParams(window.location.search);
  // const autoConnect = queryParams.get('autoConnect')?.toLowerCase() === 'true' ? true : false;
  // const [, setAuto] = useState(autoConnect);



  // const onChange = (checked: boolean) => {
  //   setAuto(checked);
  // };

  return (
      <MainPage />
  );
};

export default App;
