import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import WalletDropdown from '../WalletDropdown';

import { useWallet } from '@manahippo/aptos-wallet-adapter';
import { AptosClient, Types } from 'aptos';
import { CONTRACT_ADDRESS, getUpgradeList } from '../lib/wenApi';

import { getUserInfo, getYourWenNumber } from '../lib/wenApi';
function normalizeAddress(address: string) {
  if (!address) {
    return address;
  }
  let [, remaining] = address.split('x');
  remaining = remaining.replace(/^0+/, '');
  return `0x${remaining}`;
}

function useWalletFixed() {
  const wallet = useWallet();
  return {
    ...wallet,
    address: normalizeAddress(wallet?.account?.address as string),
  };
}

const aptosClient = new AptosClient('https://fullnode.mainnet.aptoslabs.com/v1');

const upgrades = {
  1: 1, // wpm
};
function calculateWps(upgradeList) {
  let wpm = 0;
  for (let i = 0; i < upgradeList.length; i++) {
    wpm += parseInt(upgrades[i + 1]) * upgradeList[i]; // wpm
  }

  return wpm / 60;
}

function calculateWens(lastClaimed, currentWens, upgradeList) {
  const wps = calculateWps(upgradeList);

  const timeSinceLastClaim = Date.now() / 1000 - lastClaimed;

  const wensToAdd = timeSinceLastClaim * wps;

  return Number(currentWens + wensToAdd).toFixed(3);
}

function Header() {
  return (
    <section id="header">
      <div className="flex justify-end py-10 px-10 max-md:flex-col max-md:items-center">
        <WalletDropdown />
      </div>
    </section>
  );
}

const MainPage = () => {
  const { account, signAndSubmitTransaction } = useWalletFixed();
  const [numWens, setNumWens] = useState(0);

  const wenNumberQuery = useQuery({
    queryKey: ['wenNumber', account?.address],
    queryFn: async () => getYourWenNumber(account?.address),
  });

  const upgradeListQuery = useQuery({
    queryKey: ['upgradeList'],
    queryFn: async () => getUpgradeList(),
  });

  const userQuery = useQuery({
    queryKey: ['userInfo', account?.address],
    queryFn: async () => getUserInfo(account?.address),
  });

  useEffect(() => {
    if (account?.address && !userQuery.isLoading && userQuery.data) {
      const timerID = setInterval(() => {
        setNumWens(
          calculateWens(
            (userQuery.data as any).last_claimed_timestamp_seconds,
            (userQuery.data as any).count,
            (userQuery.data as any).upgrade_count
          ) as any
        );
      }, 1000);

      return () => clearInterval(timerID);
    }
  }, [account?.address, userQuery.data]);

  const wenTx = async () => {
    try {
      if (account?.address || account?.publicKey) {
        const payload: Types.TransactionPayload = {
          type: '',
          function: `${CONTRACT_ADDRESS}::wen::click`,
          type_arguments: [],
          arguments: [],
        };
        const transactionRes = await signAndSubmitTransaction(payload);
        await aptosClient.waitForTransaction(transactionRes?.hash || '');
        wenNumberQuery.refetch();
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  const handleBuy = async (id: number) => {
    try {
      if (account?.address || account?.publicKey) {
        const payload: Types.TransactionPayload = {
          type: '',
          function: `${CONTRACT_ADDRESS}::wen::upgrade`,
          type_arguments: [],
          arguments: [
            id,
            1, // amount. hardcode to 1 for now.
          ],
        };
        const transactionRes = await signAndSubmitTransaction(payload);
        await aptosClient.waitForTransaction(transactionRes?.hash || '');
        wenNumberQuery.refetch();
        userQuery.refetch();
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  return (
    <div id="main" className="min-h-screen px-12">
      <Header />
      <div className="flex flex-col space-y-12 items-center justify-center">
        {!userQuery.isLoading && userQuery.data && <div className="text-8xl">{numWens}</div>}
        <button
          className="flex items-center justify-center h-56 w-56 rounded-full text-5xl bg-neutral-200 hover:bg-neutral-300 uppercase font-bold active:bg-neutral-400 text-white transition duration-200 ease-in-out transform px-4 py-2 border-b-4 border-gray-400 hover:border-b-2 bg-gradient-to-t from-gray-300 via-gray-400 to-gray-100 hover:translate-y-px active:via-neutral-400 active:to-gray-400 active:from-gray-400"
          onClick={() => wenTx()}>
          wen
        </button>
        <div>
          {!userQuery.isLoading && userQuery.data && (
            <p>wens per second / {calculateWps((userQuery.data as any).upgrade_count).toFixed(3)}</p>
          )}
        </div>
        <div className="text-xl border border-neutral-300 py-4 px-6 rounded-xl">
          <p className="uppercase font-medium text-sm text-gray-500">shop</p>
          {upgradeListQuery.isLoading && <div>Loading...</div>}
          {upgradeListQuery.data &&
            upgradeListQuery.data.map((upgrade: any) => (
              <button
                key={upgrade.id}
                onClick={() => handleBuy(upgrade.id)}
                className="bg-neutral-100 px-6 py-5 rounded-x flex flex-col space-y-2 rounded-xl hover:bg-neutral-200 active:bg-neutral-300 w-80">
                <div className="flex flex-row space-x-3 items-center justify-center">
                  <div className="font-semibold">{upgrade.display_name}</div>
                </div>

                <div className="flex flex-row space-x-2 items-end justify-between w-full">
                  <div className="flex flex-row items-start justify-center space-x-1.5 h-6">
                    <p className="text-sm text-red-500 pt-1.5">Cost</p>
                    <p className="font-bold text-xl">{upgrade.cost}</p>
                  </div>

                  <span className="text-green-600 text-sm">+ {upgrade.wps.toFixed(3)} wps</span>
                </div>
              </button>
            ))}
          {/* <div className='border-gray-300 border-b-2 mb-6'>
          <button className="btn btn-primary flex-end bg-white rounded-md font-medium hover:opacity-80 transition-all block" onClick={() => handleBuy(2)}>Buy Discord Shillers (1000 Wens)</button>
          <span className='text-grey-500 text-sm'>5wps (wens per second) (owned: 0)</span>
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default MainPage;
