import {
  WalletProvider,
  AptosWalletAdapter,
  MartianWalletAdapter,
  PontemWalletAdapter,
  RiseWalletAdapter,
  MsafeWalletAdapter,
} from '@manahippo/aptos-wallet-adapter';
import { ReactNode, useMemo } from 'react';

interface AptosWalletProviderProps {
  children?: ReactNode;
}

const AptosWalletProvider = ({ children }: AptosWalletProviderProps) => {
  const wallets = useMemo(
    () => [
      new RiseWalletAdapter(),
      new MartianWalletAdapter(),
      new PontemWalletAdapter(),
      new AptosWalletAdapter(),
      new MsafeWalletAdapter('https://app.m-safe.io'), 
    ],
    [],
  );

  return (
    <WalletProvider autoConnect wallets={wallets}>
      {children}
    </WalletProvider>
  );
};

export { AptosWalletProvider };
